import { render, staticRenderFns } from "./EmailsSenderView.vue?vue&type=template&id=f2fcfefe&scoped=true"
import script from "./EmailsSenderView.vue?vue&type=script&lang=js"
export * from "./EmailsSenderView.vue?vue&type=script&lang=js"
import style0 from "./EmailsSenderView.vue?vue&type=style&index=0&id=f2fcfefe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2fcfefe",
  null
  
)

export default component.exports