<template>
    <div style="height: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Usuários'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Novo usuário'"
        @onTap="$router.push({name: 'usersNew'})"
        :haveContentMargin="true"
        >

        <template #filters>
            <div class="row d-flex justify-content-start align-items-center">
                <div class="col-3 ">
                    <div class="form-group">
                        <label for="inputUserName">Nome ou e-mail do usuário</label>
                        <input class="form-control form-control-sm" id="inputUserNameOrEmail" style="box-shadow: none; min-height:56px;" placeholder="Digite o nome ou e-mail do usuário"
                        v-model="query">
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="inputUserSatus">Status</label>
                        <select class="form-control form-control-sm" id="inputUserSatus" style="box-shadow: none; min-height:56px;" 
                        v-model="status">
                            <option :value="2">Todos</option>
                            <option :value="1">Ativo</option>
                            <option :value="0">Inativo</option>
                        </select>
                    </div>
                </div>
                <div class="col d-flex justify-content-end">
                    <GlassButton
                    :loading="loading"
                    @click="getUsers"
                    > 
                        <img src="@/assets/search.png" />
                        Pesquisar
                    </GlassButton>
                </div>
            </div>
        </template>

        <!-- CONTENT -->
        <b-table v-if="renderComponent" class="custom-table" borderless sticky-header :fields="columns" :items="users" show-empty empty-text="Sem registros para mostrar no momento...">
            <template #cell(n_document)="row">
                <div class="d-flex justify-content-start align-items-center">
                    {{ formatCPF(row.item.n_document) }}
                </div>
            </template>
            <template #cell(role)="row">
                    {{ row.item.role==0 ? 'Comum' : row.item.role==1 ? 'Admin' : '---' }}
            </template>
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <b-checkbox switch v-model="row.item.status" @change="changeStatus(row.item)"></b-checkbox>
                    <!-- <b-form-checkbox switch size="sm" v-model="row.item.status" @change="changeSituation(row.item)"></b-form-checkbox> -->
                    <b-button 
                        size="sm"
                        @click="editUser(row.item.id)" class="px-2"
                        style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                    >
                        <b-icon icon="pencil-fill"></b-icon>
                    </b-button>
                    <!-- <b-button 
                        size="sm"
                        @click="editUser(row.item.id)"
                        style="color: #000; border: none; box-shadow: none; background-color: transparent; font-size:10px;"
                    >
                        <b-icon icon="pencil-fill"></b-icon>
                    </b-button>
                    <b-form-checkbox switch size="sm" @change="changeSituation(row.item.id)"></b-form-checkbox> -->
                </div>
            </template>
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>
    </div>
</template>

<script>
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
export default {
    name: 'UsersView',
    components: {
        GlassButton,
        DefaultPageComponent,
        // DefaultHeader,
    },
    mounted() {
        this.getUsers();
        this.saveLog();
    },
    data() {
        return {
            loading: false,

            query: null,
            status: 2,

            columns: [
                {
                    'id': 
                    {
                        label: 'ID',
                        class: 'text-left'
                    }
                },
                {
                    'first_name': 
                    {
                        label: 'Usuário',
                        class: 'text-left'
                    }
                },
                {
                    'email': 
                    {
                        label: 'E-mail',
                        class: 'text-left'
                    }
                },
                {
                    'n_document': 
                    {
                        label: 'CPF',
                        class: 'text-left'
                    }
                },
                {
                    'advisor_name': 
                    {
                        label: 'Assessor vinculado',
                        class: 'text-left'
                    }
                },
                {
                    'role': 
                    {
                        label: 'Tipo',
                        class: 'text-left'
                    }
                },
                {
                    'ACTIONS': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            users: [],
            renderComponent: true,
        }
    },
    methods: {
        saveLog () {
            let objLog = {
                description: 'Cadastro usuário'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        async forceRender(){
            this.renderComponent = false;
            await this.$nextTick();
            this.renderComponent = true;
        },
        async changeStatus(item){
            await this.$swal({
                title: !item.status ? 'Inativar usuário?' : 'Ativar usuário?',
                text: !item.status ? `Deseja realmente inativar o usuário ${item.first_name}?` : `Deseja realmente ativar o usuário ${item.first_name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: !item.status ? 'Inativar' : 'Ativar',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            })
            .then(async (result)=>{
                if(result.isConfirmed) {
                    item.status = !item.status;
                    await this.forceRender();
                    return;
                }
                if (!result.isConfirmed) {
                    try{
                        this.loading=true;
                        await this.$axios.put(`users/status/${item.id}`, { status: item.status ? 1 : 0 });
                    }
                    catch(error) { 
                        console.log(error);
                        this.$swal({
                            title: 'Erro',
                            text: 'Não foi possível alterar o status do usuário.',
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#C44C30',
                            allowOutsideClick: false,
                        });
                        item.status = !item.status;
                        await this.forceRender();
                    }
                    finally { this.loading=false; }
                }
            })

        },
        getUsers() {
            this.loading=true;
            this.$axios.get('users?status='+this.status + (this.query?'&search='+this.query:'')).then((response) => {
                this.users = response.data.data;
                this.users.forEach(e => e.status ? e.status = true : e.status = false);
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar usuários.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        editUser(userID) {
            this.$router.push({ name: 'usersEdit', params: { id: userID }});
        },
        changeSituation(user) {
            this.$swal({
                title: !user.status ? 'Inativar usuário?' : 'Ativar usuário?',
                text: !user.status ? `Deseja realmente inativar o usuário ${user.first_name}?` : `Deseja realmente ativar o usuário ${user.first_name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: !user.status ? 'Inativar' : 'Ativar',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            })
            return;
        },
        formatCPF(cpf) {
            if (cpf && cpf.length === 11) {
                return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
            }
            return cpf;
        },
    }
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #28A745 !important;
    background-color: #28A745 !important;
    box-shadow: none !important;
}

.custom-control-input:not(checked) ~ .custom-control-label::before {
    border-color: #CCC !important;
    box-shadow: none !important;
}

tr:nth-child(odd) {
    background-color: #DDD !important;
}
</style>
