<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            title="Listagem de e-mails"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveNextButton="true"
            :buttonText="'Novo e-mail'"
            @onTap="$router.push({name: 'emailsViewForm', params: { id: null }})"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col class="px-1" cols="3">
                        <div class="form-group">
                            <label for="inputSubject">Pesquise</label>
                            <input type="text" class="form-control form-control-sm" id="inputSubject" placeholder="Pesquise pelo assunto" style="box-shadow: none; height: 56px;"
                                v-model="query">
                        </div>
                    </b-col>
                    <b-col></b-col>
                    <b-col>
                        <b-row align-h="end">
                            <GlassButton
                            :loading="loading"
                            @click="getItems"
                            > Pesquisar
                            </GlassButton>
                        </b-row>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-table class="custom-table" borderless sticky-header :fields="columns" :items="items" show-empty>
                <template #empty="">
                    <b-col class="text-center">
                        Não há dados disponíveis
                    </b-col>
                </template>
                <template #cell(senders)="row">
                    <b-row class="m-0" align-h="center">
                        <b-button class="px-2"
                            style="color: white; height: 40px; border-radius:7px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none; font-weight:600;"
                            @click="showSenders(row.item)"
                        >
                            <span class="px-1" style="border-radius:5px; background-color:#EB694A; color:#242323;"
                            > {{ getSenders(row.item.sent_emails) }} </span>
                            <div class="px-1"
                            > / </div>
                            <span class="px-1" style="border-radius:5px; background-color:#EB694A; color:#242323;"
                            > {{ getRecipients(row.item.sent_emails) }} </span>
                        </b-button>
                    </b-row>
                </template>
                <template #cell(actions)="row">
                    <b-row class="m-0" align-h="center">
                        <b-button 
                            style="color: #EB694A; width: 39px; height: 40px; border-radius:7px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                            @click="showDetails(row.item)"
                        >
                            <b-icon icon="eye-fill"></b-icon>
                        </b-button>
                    </b-row>
                </template>
            </b-table>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>
        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
export default {
    name: "EmailsView",
    components:{
        DefaultPageComponent,
        GlassButton,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,

            query: null,

            items: [],

            columns: [
                {
                    'classification_name': 
                    {
                        label: 'Classificação',
                        class: 'text-left',
                    }
                },
                {
                    'subject': 
                    {
                        label: 'Assunto',
                        class: 'text-left',
                    }
                },
                {
                    'senders': 
                    {
                        label: 'Remetentes / Destinatários',
                        class: 'text-center',
                    }
                },
                {
                    'created_at': 
                    {
                        label: 'Data envio',
                        class: 'text-right',
                        formatter: (value)=>{
                            return this.$options.filters.formatDate(value);
                        },
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
        }
    },
    created(){
        this.saveLog();
        this.getItems();
    },
    methods: {
        getSenders(list){
            var units = [];
            list.forEach(e=>{
                if(!units.includes(e.sender_code)) units.push(e.sender_code);
            })
            return units.length;
        },
        getRecipients(list){
            var units = [];
            list.forEach(e=>{
                if(!units.includes(e.recipient_code)) units.push(e.recipient_code);
            })
            return units.length;
        },
        async getItems(){
            try{
                this.loading=true;
                var endpoint = 'email-sent';
                // if(this.selectedYear) endpoint+='?year='+this.selectedYear;
                // if(this.advisor) endpoint+='&advisor='+this.advisor;
                // if(this.cell) endpoint+='&cell='+this.cell;
    
                const response = await this.$axios.get(endpoint);
                this.items = response.data;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        showSenders(item){
            item;
        },
        showDetails(item){
            this.$router.push({ name: 'emailsViewForm', params: { id: item.id }})
        },
        saveLog () {
            let objLog = {
                description: 'E-mails'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    },
}
</script>

<style scoped>

</style>