<template>
    <div id="permissions" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Permissões'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Usuário</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="user" @change="permissions=null;">
                                <option :value="null">Selecione</option>
                                <option v-for="item in users" :key="item.id" :value="item"> {{ item.first_name+' '+item.last_name }} </option>
                            </select>
                        </div>
                    </b-col>
                    <!-- <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Status:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="status">
                                <option :value="2">Todos</option>
                                <option :value="1">Ativo</option>
                                <option :value="0">Inativo</option>
                            </select>
                        </div>
                    </b-col> -->
                    <b-col></b-col>
                    <b-col>
                        <b-row align-h="end">
                            <GlassButton
                            :disabled="!user"
                            :loading="loading"
                            @click="getPermission"
                            :width="'260px'"
                            > 
                                <img src="@/assets/search.png" />
                                Pesquisar
                            </GlassButton>
                        </b-row>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-col v-if="!permissions" style="font-size:18px !important;">
                <b-row class="p-1 pl-4" style="background: #535252; font-size:20px; font-weight:600">
                    <b-col cols="1" class="p-0"></b-col>
                    <b-col class="p-0 pt-1">
                        Funcionalidade
                    </b-col>
                    <b-col class="p-0 text-center">
                        <b-row class="m-0" align-h="center" align-v="center">
                            Permissão
                            <b-checkbox switch disabled v-model="all" class="ml-2 mt-2"></b-checkbox>
                        </b-row>
                    </b-col>
                    <b-col class="p-0">
                        <!-- Ações -->
                    </b-col>
                </b-row>
                <b-row align-h="center" class="mt-3">
                    Selecione um usuário
                </b-row>
            </b-col>
            <b-col v-else style="font-size:18px !important;">
                <b-row class="p-1 pl-4" style="background: #535252; font-size:20px; font-weight:600; position:sticky; top:0px; z-index:10;">
                    <b-col cols="1" class="p-0"></b-col>
                    <b-col class="p-0 pt-1">
                        Funcionalidade
                    </b-col>
                    <b-col class="p-0 text-center">
                        <b-row class="m-0" align-h="center" align-v="center">
                            Permissão
                            <b-checkbox switch v-model="all" class="ml-2 mt-2" @change="changeAll"></b-checkbox>
                        </b-row>
                    </b-col>
                    <b-col class="p-0">
                        <!-- Ações -->
                    </b-col>
                </b-row>
                <!-- <b-row class="p-2 pl-4" style="background: #1C1B1B; border-bottom:1px solid white;">
                    <b-col cols="1" class="p-0"></b-col><b-col class="p-0 text-left">
                        <span>Estratificação</span>
                    </b-col>
                    <b-col class="p-0 text-center">
                        <b-checkbox switch v-model="permissions.stratification"></b-checkbox>
                    </b-col><b-col class="p-0"></b-col>
                </b-row> -->
                <b-row>
                    <b-button v-b-toggle.stratification class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.stratification ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Estratificação</span>
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="stratification" class="w-100" v-model="collapse.stratification">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Estratificação</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.stratification"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Regras Pendentes</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.pending_rules"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Campanhas</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.campaigns"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row>
                    <b-button v-b-toggle.cockpit class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.cockpit ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Cockpit</span>
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="cockpit" class="w-100" v-model="collapse.cockpit">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Cockpit</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.cockpit_cockpit"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Metas</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.cockpit_goals"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row>
                    <b-button v-b-toggle.messaging class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.messaging ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Mesa Renda Variável</span> 
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="messaging" class="w-100" v-model="collapse.messaging">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Mensagens</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.messaging_messages"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Mensagens Padrões</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.messaging_default_messages"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Tags</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.messaging_tags"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Anexos</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.messaging_attachments"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Integração</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.messaging_integration"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row>
                    <b-button v-b-toggle.emails class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.emails ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Mensageria</span> 
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="emails" class="w-100" v-model="collapse.emails">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>E-mails</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.emails"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>E-mails Padrão</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.default_emails"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Configuração E-mail</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.email_configuration"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Classificação de e-mails</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.email_classification"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row>
                    <b-button v-b-toggle.top_funds class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.top_funds ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Top Funds</span> 
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="top_funds" class="w-100" v-model="collapse.top_funds">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Top Funds</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.top_funds"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Top Funds Previdência</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.top_pension_funds"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row class="p-2 pl-4" style="background: #1C1B1B; border-bottom:1px solid white;">
                    <b-col cols="1" class="p-0"></b-col><b-col class="p-0 text-left">
                        <span>Profile</span>
                    </b-col>
                    <b-col class="p-0 text-center">
                        <b-checkbox switch v-model="permissions.profile"></b-checkbox>
                    </b-col><b-col class="p-0"></b-col>
                </b-row>
                <b-row class="p-2 pl-4" style="background: #1C1B1B; border-bottom:1px solid white;">
                    <b-col cols="1" class="p-0"></b-col><b-col class="p-0 text-left">
                        <span>Pipefy</span>
                    </b-col>
                    <b-col class="p-0 text-center">
                        <b-checkbox switch v-model="permissions.pipefy"></b-checkbox>
                    </b-col><b-col class="p-0"></b-col>
                </b-row>
                <b-row>
                    <b-button v-b-toggle.report class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.report ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Relatórios</span> 
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="report" class="w-100" v-model="collapse.report">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>FGC</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.report_fgc"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Vencimentos Renda Fixa</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.report_rf_maturities"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row>
                    <b-button v-b-toggle.register class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.register ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Cadastros</span> 
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="register" class="w-100" v-model="collapse.register">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Clientes</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.register_customers"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Assessores</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.register_advisors"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Parceiro Comercial</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.register_commercial_partners"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Usuários</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.register_users"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Unidades</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.register_companies"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Células</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.register_cells"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Objetivos WFLOW</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.register_wflow_objectives"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Bancos</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.register_bank"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row>
                    <b-button v-b-toggle.import class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.import ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Importações</span> 
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="import" class="w-100" v-model="collapse.import">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Estratificação</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.import_stratification"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Diversificação Diária</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.import_daily_diversification"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Cockpit</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.import_cockpit"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Cliente XP</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.import_customer"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row>
                    <b-button v-b-toggle.config class="p-0 border-0 w-100" style="box-shadow:none !important;"> 
                        <b-row class="p-2 pl-4 m-0" style="background: #1C1B1B; border-bottom:1px solid white;">
                            <b-col cols="1" class="p-0">
                                <b-row class="m-0 h-100 mr-1" align-v="center" align-h="end"> <span class="pr-1" style="font-size:10px;">
                                    <b-icon icon="chevron-down" style="transition:.25s" :style="collapse.config ? 'transform: rotate(-180deg)' : ''"></b-icon></span> </b-row>
                            </b-col>
                            <b-col class="p-0 text-left">
                                <span style="font-size:18px">Configurações</span> 
                            </b-col><b-col></b-col><b-col></b-col>
                        </b-row>    
                    </b-button>
                    <b-collapse id="config" class="w-100" v-model="collapse.config">
                        <b-col>
                            <b-row class="p-2 pl-4" style="background: #2F2E2E; border-bottom:1px solid white;">
                                <b-col cols="1" class="p-0"></b-col><b-col class="p-0 pl-3 text-left">
                                    <span>Permissões</span>
                                </b-col>
                                <b-col class="p-0 pr-3 text-center">
                                    <b-checkbox switch v-model="permissions.config_permissions"></b-checkbox>
                                </b-col><b-col class="p-0"></b-col>
                            </b-row>
                        </b-col>
                    </b-collapse>
                </b-row>
                <b-row class="p-2 pl-4" style="background: #1C1B1B; border-bottom:1px solid white;">
                    <b-col cols="1" class="p-0"></b-col><b-col class="p-0 text-left">
                        <span>Log</span>
                    </b-col>
                    <b-col class="p-0 text-center">
                        <b-checkbox switch v-model="permissions.log"></b-checkbox>
                    </b-col><b-col class="p-0"></b-col>
                </b-row>
            </b-col>

            <template #footer>
                <b-row class="m-0" align-h="end" align-v="center">
                        <div class="form-group" style="max-width:160px;">
                            <div class="text-right">
                                <CancelButton
                                :disabled="loadingSubmit"
                                @click="reset"
                                > Cancelar
                                </CancelButton>
                            </div>
                        </div>
                        <div style="width:20px;"></div>
                        <CustomButton class="mx-0"
                        :loading="loading || loadingSubmit"
                        :disabled="isDisabled"
                        @click="save"
                        > Salvar
                        </CustomButton>
                    </b-row>
            </template>

            </DefaultPageComponent>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import GlassButton from '@/components/GlassButton.vue';
import CancelButton from '@/components/CancelButton.vue';
export default {
    name: "PermissionsView",
    components:{
        CancelButton,
        GlassButton,
        CustomButton,
        DefaultPageComponent,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,
            users: [],
            all: false,
            user: null,
            status: 2,
            permissions: null,
            collapse: {
                stratification: false,
                register: false,
                config: false,
                report: false,
                import: false,
                messaging: false,
                emails: false,
                top_funds: false,
                cockpit: false,
            },
        }
    },
    created(){
        this.getUsers();
    },
    computed:{
        isDisabled(){
            return this.loading || this.loadingSubmit || !this.user || !this.permissions;
        },
    },
    methods: {
        changeAll(){
            var keys = Object.keys(this.permissions)
            keys.forEach(key=>{
                if(typeof this.permissions[key] == 'boolean'){
                    this.permissions[key] = this.all;
                }
            })
        },
        emptyPermission(){
            return {
                "stratification": false,
                "pending_rules": false,
                "campaigns": false,

                "profile": false,

                "pipefy": false,

                "register_customers": false,
                "register_advisors": false,
                "register_commercial_partners": false,
                "register_users": false,
                "register_companies": false,
                "register_cells": false,
                "register_wflow_objectives": false,
                "register_bank": false,

                "config_permissions": false,

                "report_fgc": false,
                "report_rf_maturities": false,

                "import_stratification": false,
                "import_daily_diversification": false,
                "import_cockpit": false,
                "import_customer": false,

                "messaging_messages": false,
                "messaging_default_messages": false,
                "messaging_tags": false,
                "messaging_attachments": false,
                "messaging_integration": false,

                "emails": false,
                "default_emails": false,
                "email_configuration": false,
                "email_classification": false,
                
                "top_funds": false,
                "top_pension_funds": false,

                "cockpit_cockpit": false,
                "cockpit_goals": false,

                "log": false,
            };
        },
        reset(){
            this.user = null;
            this.status = 2;
            this.permissions = null;
            this.all = false;
        },
        openAllCollapses(){
            this.collapse.stratification=true;
            this.collapse.register=true;
            this.collapse.config=true;
            this.collapse.report=true;
            this.collapse.import=true;
            this.collapse.messaging=true;
            this.collapse.emails=true;
            this.collapse.top_funds=true;
            this.collapse.cockpit=true;
        },
        closeAllCollapses(){
            this.collapse.stratification=false;
            this.collapse.register=false;
            this.collapse.config=false;
            this.collapse.report=false;
            this.collapse.import=false;
            this.collapse.messaging=false;
            this.collapse.emails=false;
            this.collapse.top_funds=false;
            this.collapse.cockpit=false;
        },
        async getPermission(){
            if(!this.user){
                this.$swal({
                    title: 'Atenção',
                    text: 'A seleção de um usuário é obrigatória.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            try{
                this.loading=true;
                var endpoint = 'permissions';

                var queries=[];

                if(this.user) queries.push('user_id='+this.user.id);
    
                const response = await this.$axios.get(endpoint + (queries.length>0 ? '?'+queries.join('&') : ''));
                this.permissions = response.data.data[0] || this.emptyPermission();

                this.openAllCollapses();
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getUsers(){
            try{
                this.loading=true;
                var endpoint = 'users';
                const response = await this.$axios.get(endpoint+'?status='+this.status);
                this.users = response.data.data;
                this.users.sort((a,b)=>a.first_name.localeCompare(b.first_name))
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async save(){
            if(!this.user){
                this.$swal({
                    title: 'Atenção',
                    text: 'Não é possível salvar, selecione um usuário.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            if(this.permissions==null) {
                this.$swal({
                    title: 'Atenção',
                    text: 'Não é possível salvar, realize a pesquisa.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            try{
                this.loading = true;
                this.loadingSubmit = true;

                var request = null;

                this.permissions.user_id = this.user.id;

                request = await this.$axios.put('permissions', this.permissions);
                
                await Promise.all([request]);
                
                this.$swal({
                    title: 'Sucesso',
                    text: 'Permissões salvas com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Atenção',
                    text: 'Houve um erro, tente novamente!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
            finally {
                this.loading = false;
                this.loadingSubmit = false;
            }
        },
    },
}
</script>

<style scoped>
/* * /deep/ .modal-card{
    max-width:fit-content !important;
} */
</style>