<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            title="Listagem de e-mails padrão"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveNextButton="true"
            :buttonText="'Novo e-mail padrão'"
            @onTap="$router.push({name: 'defaultEmailsViewForm'})"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row class="mr-0">
                    <b-col cols="3">
                        <div class="form-group">
                            <label for="inputEmailStatus">Status</label>
                            <select class="form-control form-control-sm" id="inputEmailStatus" style="box-shadow: none; height: 56px;"
                            v-model="status">
                                <option :value="'1'"> {{ 'Ativo' }} </option>
                                <option :value="'0'"> {{ 'Inativo' }} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="form-group">
                            <label for="inputEmailType">Classificação</label>
                            <select class="form-control form-control-sm" id="inputEmailType" style="box-shadow: none; height: 56px;"
                            v-model="classification_id">
                                <option :value="null"> {{ 'Todos' }} </option>
                                <option v-for="classification in classifications" :key="classification.id" :value="classification.id"> {{ classification.title }} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="form-group">
                            <label for="inputSubject">Pesquise</label>
                            <input type="text" class="form-control form-control-sm" id="inputSubject" placeholder="Pesquise pelo assunto" style="box-shadow: none; height: 56px;"
                                v-model="query">
                        </div>
                    </b-col>
                    <b-col>
                        <b-row align-h="end">
                            <GlassButton
                            :loading="loading"
                            @click="getItems"
                            > Pesquisar
                            </GlassButton>
                        </b-row>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-table class="custom-table" borderless sticky-header :fields="columns" :items="items" show-empty>
                <template #empty="">
                    <b-col class="text-center">
                        Não há dados disponíveis
                    </b-col>
                </template>
                <template #cell(actions)="row">
                    <b-row class="m-0" align-h="end" align-v="center">
                        <b-button 
                            style="color: #EB694A; width: 39px; height: 40px; border-radius:7px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                            @click="showDetails(row.item)"
                        >
                            <b-icon icon="eye-fill"></b-icon>
                        </b-button>

                        <div class="px-2"></div>

                        <!-- <b-button 
                            style="color: #EB694A; width: 39px; height: 40px; border-radius:7px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                            @click="showEdit(row.item)"
                        >
                            <b-icon icon="pencil-fill"></b-icon>
                        </b-button>

                        <div class="px-2"></div> -->

                        <b-checkbox class="pb-2" switch size="lg" v-model="row.item.template.status" @change="activateOrInactivatedDefaultEmailView(row.item, row.item.template.status)"></b-checkbox>
                    </b-row>
                </template>
            </b-table>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>
        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
export default {
    name: "EmailsView",
    components:{
        DefaultPageComponent,
        GlassButton,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,

            status: 1,
            classification_id: null,
            query: '',

            classifications: [],

            items: [],

            columns: [
                {
                    'classification_title': 
                    {
                        label: 'Classificação',
                        class: 'text-left',
                    }
                },
                {
                    'template.subject': 
                    {
                        label: 'Assunto',
                        class: 'text-left',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
        }
    },
    created(){
        this.saveLog();
        this.getClassifications();
        this.getItems();
    },
    methods: {
        async getClassifications(){
            try{
                this.loading=true;
                var endpoint = 'email-classification';
    
                const response = await this.$axios.get(endpoint);
                this.classifications = response.data.data;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getItems(){
            try{
                this.loading=true;
                var endpoint = 'email-template?status='+this.status;

                if (this.query != '')
                    endpoint += '&subject=' + this.query;
    
                if (this.classification_id != null)
                    endpoint += '&classification_id=' + this.classification_id;

                const response = await this.$axios.get(endpoint);
                this.items = response.data;
                this.items.forEach(item => {
                    item.template.status = item.template.status == 1 ? true : false;
                })
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        showDetails(item){
            this.$router.push({ name: 'defaultEmailsViewForm', params: { item: item }})
        },
        showEdit(item){
            item;
        },
        activateOrInactivatedDefaultEmailView(emailTemplate, status) {
            this.$swal({
                title: !status ? 'Inativar e-mail padrão?' : 'Ativar e-mail padrão?',
                text: !status ? `Deseja realmente inativar o e-mail padrão ${emailTemplate.template.subject}?` : `Deseja realmente ativar o e-mail padrão ${emailTemplate.template.subject}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: !status ? 'Inativar' : 'Ativar',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then((result) => {
                if (!result.isConfirmed) {
                    let url = `email-template/${emailTemplate.template.id}`;

                    this.$axios.put(url, emailTemplate.template).then((response) => {
                        if(response.status != 200) {
                            this.$swal({
                                title: 'Erro',
                                text: status ? 'Erro ao ativar a mensagem padrão.' : 'Erro ao inativar o assessor.',
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#C44C30',
                                allowOutsideClick: false,
                            });
                            return;
                        }
                        this.$swal({
                            title: 'Sucesso',
                            text: status ? 'Mensagem padrão ativada com sucesso.' : 'Mensagem padrão inativada com sucesso.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#C44C30',
                            allowOutsideClick: false,
                        });
                        this.getItems();
                    });
                } else {
                    this.items.forEach(item => {
                        if(item.template.id == emailTemplate.template.id){
                            item.template.status = !status;
                        }
                    });
                }
            });
        },
        saveLog () {
            let objLog = {
                description: 'E-mails Padrão'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    },
}
</script>

<style scoped>

</style>