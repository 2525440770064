<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            title="Seleção de remetentes e destinatários"
            :haveBackButton="true"
            @onBack="$router.push({name: 'emailsViewForm'})"
            >

            <template #filters>
                <b-row>
                    <b-col cols="7">
                        <b-row>
                            <b-col cols="4">
                                <div class="form-group">
                                    <label for="inputEmailAdvisor">Assessor</label>
                                    <select @change="verifyAdvisor" class="form-control form-control-sm" id="inputEmailAdvisor" style="box-shadow: none; height: 56px;"
                                    v-model="advisor">
                                        <option :value="null"> {{ 'Selecione o assessor' }} </option>
                                        <option :value="'TODOS'"> {{ 'Todos' }} </option>
                                        <option v-for="adv,idx in advisors" :key="idx" :value="adv.id"> {{ adv.first_name+' '+adv.last_name }} </option>
                                    </select>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="form-group">
                                    <label for="inputEmailCustomer">Cliente</label>
                                    <input type="text" class="form-control form-control-sm" id="inputEmailCustomer" placeholder="Informe um cliente" style="box-shadow: none; height: 56px;"
                                        v-model="customer" :disabled="!advisor || advisor=='TODOS'">
                                </div>
                            </b-col>
                            <b-col cols="3" class="d-flex justify-content-end" style="max-width:max-content;">
                                <CustomButton 
                                @click="search"
                                :disabled="!advisor || advisor=='TODOS'"
                                > Pesquisar
                                </CustomButton>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="5">
                        <b-row class="mx-0" align-h="end">
                            <CustomButton 
                            @click="visualize"
                            > Visualizar
                            </CustomButton>
                        </b-row>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <div v-if="render" class="h-100 w-100">
                <b-row class="m-0">
                    <b-col cols="7" class="my-3 px-4">
                        <span style="font-size:18px;">Listagem de clientes</span>

                        <b-table style="height:calc(100vh - 410px) !important;" class="custom-table" borderless sticky-header :fields="customerColumns" :items="customerItems" >
                        <template #head(checkbox)="data">
                            <b-row v-if="data" class="m-0" align-h="center">
                                <b-checkbox @change="(value)=>value ? customersSelected=customerItems : customersSelected=[]"></b-checkbox>
                            </b-row>
                        </template>
                        <template #cell(checkbox)="row">
                            <b-row v-if="row" class="m-0" align-h="center">
                                <b-checkbox :disabled="destinataryItems.some(destinatary=>destinatary.customers.some(dc=>dc.xp_code==row.item.xp_code))" v-model="customersSelected" :value="row.item"></b-checkbox>
                            </b-row>
                        </template>
                        <template #cell(xp_code)="row">
                            <div :style="destinataryItems.some(destinatary=>destinatary.customers.some(dc=>dc.xp_code==row.item.xp_code)) ? 'opacity:0.5;' : ''">
                                {{ row.item.xp_code }}
                            </div>
                        </template>
                        <template #cell(customer_full_name)="row">
                            <div :style="destinataryItems.some(destinatary=>destinatary.customers.some(dc=>dc.xp_code==row.item.xp_code)) ? 'opacity:0.5;' : ''">
                                {{ row.item.customer_full_name }}
                            </div>
                        </template>
                        <template #cell(email)="row">
                            <div :style="destinataryItems.some(destinatary=>destinatary.customers.some(dc=>dc.xp_code==row.item.xp_code)) ? 'opacity:0.5;' : ''">
                                {{ row.item.email }}
                            </div>
                        </template>
                        <template #cell(birthday)="row">
                            <div :style="destinataryItems.some(destinatary=>destinatary.customers.some(dc=>dc.xp_code==row.item.xp_code)) ? 'opacity:0.5;' : ''">
                                {{ row.item.birthday }}
                            </div>
                        </template>
                    </b-table>
                    </b-col>





                    <b-col cols="5" class="my-3 px-4">
                        <span style="font-size:18px;">Destinatários selecionados por Assessor</span>

                        <b-table style="height:calc(100vh - 410px) !important;" class="custom-table" borderless sticky-header :fields="destinataryColumns" :items="destinataryItems">
                        <template #cell(destinataries)="row">
                            <b-row class="m-0" align-h="center">
                                <b-button class="px-2"
                                    style="color: white; border-radius:7px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none; font-weight:600;"
                                    @click="showDestinataries(row.item)"
                                >
                                    <span class="px-1" style="background-color:#EB694A; color:#242323;"
                                    > {{ row.item.customers.length }} </span>
                                </b-button>
                            </b-row>
                        </template>
                        <!-- <template #cell(actions)="row">
                            <b-row class="m-0" align-h="center">
                                <b-button 
                                    style="color: #EB694A; width: 39px; height: 40px; border-radius:7px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                    @click="removeDestinatary(row.item)"
                                >
                                    <b-icon icon="trash-fill"></b-icon>
                                </b-button>
                            </b-row>
                        </template> -->
                    </b-table>
                    </b-col>
                </b-row>
            </div>

            <template #footer>
                <b-row>
                    <b-col cols="7">
                        <b-row class="mx-0" align-h="end">
                            <CustomButton 
                            @click="add"
                            :disabled="customersSelected.length==0"
                            > Adicionar
                            </CustomButton>
                        </b-row>
                    </b-col>

                    <b-col cols="5">
                        <b-row class="mx-0" align-h="end">
                            <!-- :disabled="!isValid" -->
                            <CustomButton 
                            :disabled="destinataryItems.length==0"
                            @click="send"
                            > Envia e-mails
                            </CustomButton>
                        </b-row>
                    </b-col>
                </b-row>
            </template>

            </DefaultPageComponent>

            <b-modal id="modalItemsId" :title="'Assessor: '+ modalItem?.advisor?.name" body-bg-variant="dark" centered size="lg" hide-footer>
                <b-table class="custom-table" borderless sticky-header :fields="customerColumns" :items="modalItem?.customers" style="height:calc(100vh - 200px) !important;">
                        <template #cell(actions)="row">
                            <b-row class="m-0" align-h="center">
                                <b-button 
                                    style="color: #EB694A; width: 39px; height: 40px; border-radius:7px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                    @click="removeDestinatary(row.item, row.index)"
                                >
                                    <b-icon icon="trash-fill"></b-icon>
                                </b-button>
                            </b-row>
                        </template>
                    </b-table>
            </b-modal>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: "EmailsNewView",
    components:{
        DefaultPageComponent,
        CustomButton,
    },
    data(){
        return {
            render: true,
            loading: false,
            loadingSubmit: false,

            advisor: null,
            customer: null,

            item: {},

            advisors: [],

            items: [],
            customerItems: [],
            destinataryItems: [],

            modalItem: null,

            customersSelected : [],

            customerColumns: [
                {
                    'checkbox': 
                    {
                        label: '',
                        class: 'text-left',
                    }
                },
                {
                    'xp_code': 
                    {
                        label: 'Código',
                        class: 'text-right',
                    }
                },
                {
                    'customer_full_name': 
                    {
                        label: 'Nome',
                        class: 'text-left',
                    }
                },
                {
                    'email': 
                    {
                        label: 'E-mail',
                        class: 'text-left',
                    }
                },
                {
                    'birthday': 
                    {
                        label: 'Data nascimento',
                        class: 'text-right',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],

            destinataryColumns: [
                {
                    'advisor.name': 
                    {
                        label: 'Assessor',
                        class: 'text-left',
                    }
                },
                {
                    'destinataries': 
                    {
                        label: 'Destinatários',
                        class: 'text-center',
                    }
                },
            ],
        }
    },
    created(){
        this.getAdvisors();

        if(!this.$route.params.item)
            this.$router.push({name: 'emailsViewForm'});
        else this.item = this.$route.params.item;
    },
    computed:{
        isValid(){
            return Boolean(false
                // this.email_option
                // && this.subject
                // && this.message
            );
        },
    },
    methods: {
        async forceRender(){
            this.render=false;
            await this.$nextTick();
            this.render=true;
        },
        async getAdvisors(){
            try{
                this.loading=true;
                var endpoint = 'advisors';
                const response = await this.$axios.get(endpoint);
                this.advisors = response.data.data;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        verifyAdvisor(){
            if(this.advisor=='TODOS'){
                this.$swal({
                    title: 'Atenção',
                    html: `Ao selecionar a opção 'Todos', serão incluídos todos os assessores e seus respectivos clientes na lista de envio. Verifique cuidadosamente antes de prosseguir, pois essa ação pode gerar um grande volume de destinatários. <br/><br/> Deseja continuar?`,
                    icon: 'question',
                    showDenyButton: true,
                    confirmButtonText: 'Cancelar',
                    confirmButtonColor: '#C7300C',
                    denyButtonText: 'Sim, continuar',
                    denyButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                        denyButton: 'custom-confirm-button-class',
                    }
                }).then(async (result) => {
                    if (!result.isConfirmed) {
                        this.getCustomers();
                    }
                    else{
                        this.advisor=null;
                    }
                });
            }
            else this.getCustomers();
        },
        async getCustomers(search){
            try{
                this.loading=true;

                this.items = [];
                if(this.advisor=='TODOS') this.destinataryItems=[];
                this.customerItems = [];
                
                var endpoint = 'advisors/customers-details';
                var queries = [];
                
                if(this.advisor!='TODOS') queries.push('advisor_id='+this.advisor);
                if(search) queries.push('search='+search);

                const response = await this.$axios.get(endpoint + (queries.length>0?'?':'') + queries.join('&'));
                this.items = response.data;

                if(this.items.length==1){
                    this.customerItems = [...this.items[0].customers]

                    this.customerItems.forEach(customer=>{
                        if(this.destinataryItems.some(destinatary=>destinatary.customers.some(dc=>dc.xp_code==customer.xp_code)))
                            this.customersSelected.push(customer);

                    })
                }
                else if(this.advisor=='TODOS'){
                    this.destinataryItems = this.items
                }

                if(this.customerItems.length==0){
                    var adv = this.advisors.find(e=>e.id==this.advisor);
                    this.$swal({
                        title: 'Atenção',
                        text: 'Nenhum cliente encontrado para o assessor '+adv.first_name+' '+adv.last_name+'.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                }


                // this.items.forEach(item=>{
                //     this.customerItems.push(...item.customers)
                // })
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        search(){
            if(!this.advisor || this.advisor=='TODOS'){
                this.$swal({
                    title: 'Atenção',
                    text: 'Selecione um assessor.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            this.getCustomers(this.customer)
        },
        showDestinataries(item){
            this.modalItem = item;
            this.$bvModal.show('modalItemsId')
        },
        removeDestinatary(item, idx){
            this.$swal({
                title: 'Excluír destinatário',
                text: `Tem certeza que deseja excluír o destinatário ${item.customer_full_name} da lista de envio de e-mails?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                denyButtonText: 'Sim, continuar',
                denyButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    denyButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    this.modalItem.customers.splice(idx, 1);

                    // var tempIdx = this.items.findIndex(e=>e.advisor.code==this.modalItem.advisor.code)
                    // this.items[tempIdx] = this.modalItem;

                    var tempIdx = this.destinataryItems.findIndex(e=>e.advisor.code==this.modalItem.advisor.code)
                    this.destinataryItems[tempIdx] = this.modalItem;
                }
            });
        },
        validate(){
            if(!this.isValid){
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha o formulário antes de continuar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false
            }
            else return true;
        },
        visualize(){},
        add(){
            var temp = {
                advisor: this.items[0].advisor,
                customers: this.removeDuplicates(this.customersSelected)
            } 

            var idx = this.destinataryItems.findIndex(d=>d.advisor.code==this.items[0].advisor.code);
            if(idx>=0)
                this.destinataryItems[idx]=temp;
            else
                this.destinataryItems.push(temp);

            this.customerItems = [];
            this.customersSelected = [];
            this.forceRender();
        },
        send(){
            if(this.destinataryItems.length==0) return;

        },
        removeDuplicates(array) {
            const seen = new Set();
            return array.filter(item => {
                const json = JSON.stringify(item);
                if (seen.has(json)) {
                    return false;
                }
                seen.add(json);
                return true;
            });
        }
    },
}
</script>

<style scoped>
* /deep/ .modal-content{
    border-radius: 20px !important;
}
* /deep/ .modal-body{
    background-color: #1C1B1B !important;
}
* /deep/ .modal-header{
    border: none !important;
}
* /deep/ .modal-header .close{
    color: white !important;
}
</style>